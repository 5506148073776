import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'imwalkingde';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    appBanner: {
      appUrl: 'https://app.adjust.com/8tdua1c_mxwc3rs',
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    dynamicYieldEnabled: false,
    clientId: 'ImWalkingDe',
    company: {
      name: 'ImWalking',
      seoTitleName: "I'm walking",
    },
    enableCustomerNrLogin: true,
    installmentCalculator: {
      hasLegalHints: true,
      hasInfoCallToAction: false,
      InstallmentCalculatorCallToActionLayout: { layout: 'Layout1' },
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    order: {
      seals: {
        ehi: {
          enabled: true,
          url: 'https://zertifikat.ehi-siegel.de/de/zertifikat/s/900150983cd24fb0d6963f7d28e17f72/',
        },
      },
      assets: {
        gifs: {
          success: {
            url: 'https://bilder.imwalking.de/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
      premium: {
        enabled: true,
      },
      hasCo2Compensation: true,
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.OpenSansBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
      },
    },
    headerLinks: {
      'de-DE': {
        serviceLink: 'https://www.baur.de/service-hilfe',
      },
    },
    usp: {
      isEnabled: false,
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          {
            locale: 'de',
            params: {
              type: ['full'],
            },
          },
          {
            locale: 'de',
            params: {
              type: ['socket'],
            },
          },
        ],
      },
    },
    forms: {
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.order', value: 'order' },
          { label: 'forms.type.subject.option.datasecurity', value: 'datasecurity' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.personalData', value: 'personalData' },
          { label: 'forms.type.subject.option.praemie', value: 'praemie' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          {
            label: 'forms.type.subject.option.complaint',
            value: 'complaint',
          },
          { label: 'forms.type.subject.option.complaint.returnsprocessing', value: 'return' },
        ],
      },
      newsletterUnsubscribe: {
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|99',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-monthly',
                value: 'frequency|3',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.monthly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              defaultValue: '7',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSheet: {
        enabled: false,
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.imwalking.de/service-hilfe/newsletter/abmelden',
        },
      },
      validation: {
        rules: {
          customerId: {
            regex: /^[A-Za-z0-9.\-ßÄÖÜäöü ]*$/,
            errorMessage: {
              id: 'forms.error.no.special.characters',
              defaultMessage: 'Sonderzeichen sind nicht erlaubt',
            },
          },
        },
      },
    },
    headerSlots: {
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    lifeSupportForBasicallyDeadTenant: true,
    host: {
      name: tenantId,
      domain: 'imwalking.de',
    },
    i18n: {
      defaultLocale: 'de-DE',
      locales: ['de-DE'],
      languages: ['de'],
    },
    search: {
      personalizationSegmentSource: 'baur',
    },
    navigation: {
      firstLevel: {
        color: 'grey.dark',
      },
    },
    product: {
      voucherPrice: {
        enabled: false,
      },
      cliplister: {
        enabled: false,
      },
      mazing: {
        enabled: false,
      },
      aplus: {
        enabled: false,
      },
      hasSwogo: false,
      hasCarbonHint: false,
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    tenantId,
    tracking: {
      soastaId: 'N442K-V2GA3-Y3W77-XGDSN-7993P',
      tagmanagerId: 'GTM-WQJ8FMZ',
      googleSiteVerificationID: ['m4m1uAb-2jo3tYGPpdPe9QBqL7o3xrCxFLS0pyoJtA0'],
      msvalidateID: 'A5FDCE2E03DF648A7B16A2F9CDE6C0DC',
      schemaOrgOrganisation: {
        'de-DE': {
          addressCountry: 'DE',
          addressRegion: 'BY',
          addressLocality: 'Burgkunstadt',
          alternateName: ['im walking', 'imwalking', 'i am walking', 'i m walking'],
          areaServed: 'DE',
          contactEmail: 'service@imwalking.de',
          contactUrl: 'https://www.imwalking.de/service-hilfe/ueber-uns/kontakt',
          description:
            "I'm walking bietet immer die neuesten Modetrends. Dafür spüren unsere Trend-Einkäufer die aktuellen Schuhtrends auf internationalen Modemessen für dich auf und bringen so ein ständig wechselndes Sortiment in den Shop. Unser Lifestyle-Angebot besteht überwiegend aus modischen Damenschuhen in gehobener Qualität, ergänzt um Herren-, Kinder- und Sportschuhe, sowie modische Accessoires - Lass dich inspirieren!",
          email: 'service@imwalking.de',
          hoursAvailable: ['Mo-Su 6:00-23:00'],
          legalName: 'BAUR Versand GmbH & Co. KG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: "I'm walking",
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/imwalking/',
            'https://www.instagram.com/im.walking/',
            'https://www.pinterest.de/imwalking/',
            'https://www.youtube.com/user/IMWALKINGde',
          ],
          telephone: '+49-9572-5070',
          url: 'https://www.imwalking.de',
          postalCode: '96224',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
          potentialAction: {
            target: 'https://www.imwalking.de/s/',
          },
          streetAddress: 'Bahnhofstraße 10',
        },
      },
    },
    headerInformation: {
      de: {
        description:
          'Schuhe für Damen & Herren ✅ 10% Rabatt für deine Newsletter-Anmeldung ✅ Kauf auf Rechnung ✅ Gratis Rückversand ► Über 1.000 Marken ||► im Online-Shop ◄||',
        title: "Schuhe Online Shop ▷ Schuhe auf Rechnung kaufen | I'm walking",
        canonical: 'https://www.imwalking.de',
        robots: 'noodp,index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      enabled: false,
      tenantId: '2000',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'de',
      alternatives: [
        {
          flag: 'de',
          shops: [{ closeLayer: true }],
        },
      ],
    },
    bookings: {
      overview: {
        hasExplicitMaturities: true,
        showOpenPayment: 'installments',
      },
    },
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
